import Filters from "../components/games/Filters";
import GameCategory from "../components/UserHome/GameCategory";

const GamesCategories = () => {
  return (
    <div
      id="games-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
        paddingBottom: "25px",
      }}
    >
      <div className="games-container flex-grow-1">
        {Filters.gameTypes.map((gameType) => {
          return <GameCategory img={gameType.img_path} name={gameType.name} />;
        })}
      </div>
    </div>
  );
};

export default GamesCategories;
