import "./changePassModal.css"
import ChangePassContent from "./changePassContent/changePassContent";

const ChangePassModal = (props) => {
  const { modalType, closeModal } = props;

  return (
    <div
      id="change-pass-modal"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-fullscreen-md-down modal-lg modal-dialog-scrollable"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body p-0">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
                left: "auto",
                zIndex: "1",
              }}
            ></button>
            <div id="outer-div" className="d-flex w-100">
              <img
                id="fixed-img"
                className="d-none d-lg-block"
                src="/loginSignup/eduBoy.svg"
                alt="image"
              ></img>
              <div
                id="inner-div"
                className="w-100"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "safe center",
                }}
              >
                <div>
                  {modalType == "LOGIN" ? (
                    <ChangePassContent />
                  ) : (
                    <ChangePassContent />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassModal;
