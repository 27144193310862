import ExcludeNavbar from "../components/ExcludeNavbar";
import ResetPassForm from "../components/login/ResetPassForm";

const ResetPassword = () => {
  return (
    <div>
      <ExcludeNavbar />
      <ResetPassForm />
    </div>
  );
};

export default ResetPassword;
