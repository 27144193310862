const MusicAlbumCarousel = (props) => {
  const { name, image } = props.album;

  return (
    <div
      style={{
        background: "white",
        borderRadius: "25px",
        padding: "20px",
        boxShadow: "rgba(0, 0, 0, 0.2) 5px 5px 20px 0px",
      }}
    >
      <img
        style={{
          borderRadius: "15px",
        }}
        className="w-100"
        src={image}
      />
      <h5
        className="my-2"
        style={{
          fontFamily: "Poppins",
          fontWeight: "600",
        }}
      >
        {name}
      </h5>
      <a
        href={`https://clubbaby.com/ms_collection/${name
          .toLowerCase()
          .split(" ")
          .join("-")}`}
      >
        <button
          className="btn"
          style={{
            background: "#5980db",
            color: "white",
          }}
        >
          View Album
        </button>
      </a>
    </div>
  );
};

export default MusicAlbumCarousel;
