import { useState } from "react";
import usePrivateRoute from "../../hooks/useAxiosPrivate";
import "./NewUser.css";
import Swal from "sweetalert2";
import { joinCapitals } from "../../scripts/helper";

const NewUser = (props) => {
  const { title, roles } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");

  const authRoute = usePrivateRoute();

  const clearFormData = () => {
    setName("");
    setEmail("");
    setPassword("");
    setType("");
  };

  const validatePassword = (password) => {
    if (password.length < 8 || password.length > 24) {
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      return false;
    }
    if (!/\d/.test(password)) {
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const createUser = async (e) => {
    e.preventDefault();

    const user = {
      name,
      email,
      password,
      type,
    };

    if (!validateEmail(user.email)) {
      Swal.fire({
        title: "Error!",
        html: `Please enter a valid email`,
        icon: "error",
        confirmButtonText: "Try again",
      });
      return;
    }
    if (!validatePassword(user.password)) {
      Swal.fire({
        title: "Error!",
        html: `Password must contain a capital letter and a digit, and contain 8 or more characters and less than 24 characters`,
        icon: "error",
        confirmButtonText: "Try again",
      });
      return;
    }

    Swal.fire({
      title: "Creating User!",
      html: "It might take some time...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await authRoute.post(`/userinfo`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("User created successfully");
      Swal.fire({
        title: "Success!",
        text: "User created successfully!",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          clearFormData();
        }
      });
    } catch (error) {
      console.error("Error creating user:", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <h1>Add New {title}</h1>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <form id="admin-create-user-form">
          <div className="form-floating mb-3">
            <input
              id="user-name-input"
              className="form-control"
              placeholder=""
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="user-name-input">Full Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              id="user-name-input"
              className="form-control"
              placeholder=""
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="user-name-input">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input
              id="user-name-input"
              className="form-control"
              placeholder=""
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label htmlFor="user-name-input">Password</label>
          </div>
          <div className="form-floating mb-3">
            <select
              className="form-select py-0"
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option disabled selected hidden value="">
                Select Role
              </option>
              {roles.map((role, index) => {
                return (
                  <option
                    key={index}
                    value={joinCapitals(role.name).toLowerCase()}
                    disabled={role.disabled}
                  >
                    {role.name}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            style={{
              width: "100%",
              padding: "10px",
            }}
            className="btn btn-dark-blue"
            onClick={createUser}
          >
            Add {title}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewUser;
