import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import TOS from "./pages/TOS";
import RequireAuth from "./components/Auth/RequireAuth";
import RequireUnauth from "./components/Auth/RequireUnauth";
import { AuthProvider } from "./context/AuthProvider";
import PersistLogin from "./components/PersistLogin";
import GamesCategories from "./pages/GamesCategories";
import Membership from "./pages/Membership";
import RequireRoles from "./components/Auth/RequireRoles";
import Admin from "./pages/Admin";
import GameDisplay from "./pages/GameDisplay";
import PlayGame from "./pages/PlayGame";
import User from "./pages/User";
import UsersList from "./pages/UsersList";
import AdminsList from "./pages/AdminsList";
import AdminGames from "./pages/AdminGames";
import PaymentPage from "./pages/PaymentPage";
import MembershipWrapper from "./components/membership/MembershipWrapper";
import PaymentPageSuccess from "./pages/PaymentPageSuccess";
import AdminPaymentHistory from "./pages/AdminPaymentHistory";
import ResetPassword from "./pages/ResetPassword";
import PasswordResetPage from "./pages/PasswordResetPage";
import UploadPdf from "./pages/UploadPdf";
import NotFound from "./pages/NotFound";
import History from "./pages/History";
import AddUser from "./pages/AddUser";
import AddAdmin from "./pages/AddAdmin";
import LoginModal from "./components/loginModal/loginModal";
import FreeWorkbooks from "./pages/FreeWorkbooks";
import SelectedWorksheet from "./pages/SelectedWorksheet";
import AdminTestimonials from "./pages/AdminTestimonials";
import { ModalProvider } from "./context/LoginModalContext";
import { UserImageProvider } from "./context/UserImageContext";
import Page from "./pages/Page";
import AdminDevGame from "./pages/AdminDevGame";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <ModalProvider>
            <UserImageProvider>
              <Header />
              <LoginModal />
              <div className="pages">
                <Routes>
                  <Route element={<PersistLogin />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/worksheets">
                      <Route
                        path=""
                        element={
                          <Page title="Worksheets">
                            <FreeWorkbooks />
                          </Page>
                        }
                      />
                      <Route
                        path=":worksheetName"
                        element={
                          <Page title="Worksheets">
                            <SelectedWorksheet />
                          </Page>
                        }
                      />
                    </Route>
                    <Route path="/games">
                      <Route
                        path=""
                        element={
                          <Page title="Games">
                            <GamesCategories />
                          </Page>
                        }
                      />
                      <Route
                        path=":gameType"
                        element={
                          <Page title="Games">
                            <GameDisplay />
                          </Page>
                        }
                      />
                      <Route
                        path="play/:gameId"
                        element={
                          <Page title="Games">
                            <PlayGame />
                          </Page>
                        }
                      />
                    </Route>
                    <Route element={<MembershipWrapper />}>
                      <Route path="/membership" element={<Membership />} />
                    </Route>
                    <Route element={<RequireUnauth />}>
                      <Route path="/reset-pass" element={<ResetPassword />} />
                      <Route
                        path="/passwordReset"
                        element={<PasswordResetPage />}
                      />
                    </Route>
                    <Route path="/tos" element={<TOS />} />
                    <Route element={<RequireAuth />}>
                      <Route element={<RequireRoles />}>
                        <Route element={<MembershipWrapper />}>
                          <Route
                            path="/membership/pay"
                            element={<PaymentPage />}
                          />
                        </Route>
                        <Route
                          path="/membership/pay/success"
                          element={<PaymentPageSuccess />}
                        />

                        <Route
                          path="/profile"
                          element={
                            <Page title="Profile">
                              <User />
                            </Page>
                          }
                        />
                      </Route>
                      <Route element={<RequireRoles />}>
                        <Route path="/admin">
                          <Route
                            path=""
                            element={
                              <Page title="Admin | Dashboard">
                                <Admin />
                              </Page>
                            }
                          />
                          <Route
                            path="users"
                            element={
                              <Page title="Admin | Users">
                                <UsersList />
                              </Page>
                            }
                          />
                          <Route
                            path="admins"
                            element={
                              <Page title="Admin | Admins">
                                <AdminsList />
                              </Page>
                            }
                          />
                          <Route path="games">
                            <Route
                              path=""
                              element={
                                <Page title="Admin | Games">
                                  <AdminGames />
                                </Page>
                              }
                            />
                            <Route
                              path="dev"
                              element={
                                <Page title="Admin | Game Dev">
                                  <AdminDevGame />
                                </Page>
                              }
                            />
                          </Route>
                          <Route
                            path="worksheets"
                            element={
                              <Page title="Admin | Worksheets">
                                <UploadPdf />
                              </Page>
                            }
                          />
                          <Route
                            path="payment-history"
                            element={
                              <Page title="Admin | Payment History">
                                <AdminPaymentHistory />
                              </Page>
                            }
                          />
                          <Route
                            path="history"
                            element={
                              <Page title="Admin | History">
                                <History />
                              </Page>
                            }
                          />
                          <Route
                            path="admin-create"
                            element={
                              <Page title="Admin | Create Admin">
                                <AddAdmin />
                              </Page>
                            }
                          />
                          <Route
                            path="user-create"
                            element={
                              <Page title="Admin | Create User">
                                <AddUser />
                              </Page>
                            }
                          />
                          <Route
                            path="testimonials"
                            element={
                              <Page title="Admin | Testimonials">
                                <AdminTestimonials />
                              </Page>
                            }
                          />
                          <Route
                            path="*"
                            element={
                              <Page title="Admin | Not Found">
                                <NotFound admin={true} />
                              </Page>
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Routes>
              </div>
              <Footer />
            </UserImageProvider>
          </ModalProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
