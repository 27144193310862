import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Filters from "../Filters";
import "./EditUserModal.css";
import { useEffect, useRef, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import RichTextEditor from "../../RichTextEditor";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";
import { joinCapitals } from "../../../scripts/helper";

const EditUserModal = (props) => {
  const { editedUser, setNewUser, roles } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [premium, setPremium] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const authRoute = usePrivateRoute();

  const closeModal = () => {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("edit-user-modal")
    );
    modal.hide();
  };

  const initEditedValues = () => {
    setName(editedUser.name);
    setEmail(editedUser.email);
    setRole(editedUser.type);
    setPremium(editedUser.premium);
  };

  useEffect(() => {
    if (editedUser) {
      initEditedValues();
    }
  }, [editedUser]);

  const handleUserUpdate = async () => {
    Swal.fire({
      title: "Are you sure you want to save your changes?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsUploading(true);

        authRoute
          .patch(
            `/userinfo/${editedUser._id}`,
            {
              type: role,
              premium,
              name,
              email,
            },
            {
              "content-type": "application/json",
            }
          )
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The user has been updated successfully.",
              icon: "success",
            });
            setNewUser(response.data);
            setIsUploading(false);
            closeModal();
          })
          .catch((error) => {
            console.error("Error updating user:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
            setIsUploading(false);
          });
      }
    });
  };

  const clearFormData = () => {
    setName("");
    setEmail("");
    setRole("");
    setPremium("");
  };

  useEffect(() => {
    const modalElement = document.getElementById("edit-user-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  useEffect(() => {
    const myModal = new bootstrap.Modal(
      document.getElementById("edit-user-modal")
    );

    const closeButton = document.querySelector(".modal-close-button");
    closeButton.addEventListener("click", () => {
      myModal.hide();
    });
  }, []);

  const handleCancelButton = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("edit-user-modal")
    );

    myModal.hide();
  };

  return (
    <div
      className="modal fade"
      id="edit-user-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="edit-user-modalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-50"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="edit-user-modalLabel">
              EDIT USER
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="btn-close btn-close-white modal-close-button"
              {...(isUploading ? { tabIndex: -1 } : {})}
            ></button>
          </div>
          <div className="modal-body pb-0">
            <form>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="floatingInput"
                  placeholder=""
                  value={name}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                  onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="floatingInput">Name</label>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="floatingInput"
                  placeholder=""
                  value={email}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="floatingInput">Email</label>
              </div>

              <div className="form-floating mb-3">
                <select
                  className="form-select py-0"
                  name="type"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option disabled selected hidden value="">
                    Select Role
                  </option>
                  {roles.map((role, index) => {
                    return (
                      <option
                        key={index}
                        value={joinCapitals(role).toLowerCase()}
                      >
                        {role}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-floating">
                <div
                  className={`form-control d-flex justify-content-center align-items-center p-0 btn ${
                    premium ? "btn-success" : "btn-danger"
                  }`}
                  onClick={(e) => setPremium(!premium)}
                >
                  {premium ? "Premium" : "Not Premium"}
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-danger modal-cancel-button"
              id="modal-cancel-button"
              onClick={handleCancelButton}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              Close
            </button>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#845adf",
                color: "white",
              }}
              onClick={handleUserUpdate}
              disabled={isUploading}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {isUploading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
