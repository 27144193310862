import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HashLink = (props) => {
  const { href, title, img, description } = props;

  return (
    <div
      style={{
        borderRadius: "25px",
        background: "white",
      }}
      className="d-flex justify-content-center h-100"
    >
      <a
        className="d-flex flex-column justify-content-start text-center"
        href={href}
        draggable="true"
        onDragStart={(e) => e.preventDefault()}
      >
        <div
          style={{
            height: "75px",
            width: "auto",
            margin: "20px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img className="h-100" src={img} />
        </div>
        <h3
          style={{
            fontFamily: "Poppins",
          }}
        >
          {title}
        </h3>
        <div
          className="pb-3"
          style={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            flex: "1",
          }}
        >
          <p className="p-3 fw-medium">{description}</p>
          <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
        </div>
      </a>
    </div>
  );
};

const sections = [
  {
    href: "#games",
    title: "Games",
    img: "/homeScreen/games-section.png",
    description: `Club Baby EDU's exiting and
educational games mix fun with
learning, designed to develop
skills for toddlers and young
children in an engaging way.`,
  },
  {
    href: "#worksheets",
    title: "Worksheets",
    img: "/homeScreen/worksheets-section.png",
    description: `Our free worksheets in English,
Math, and Science are fun and
educational, ideal for reinforcing
learning at home or during
homes chooling sessions.`,
  },
  {
    href: "#music",
    title: "Music",
    img: "/homeScreen/music-section.png",
    description: `Explore learning through music
with our Club Baby EDU Music
Store. Educational songs and
melodies make learning
enjoyable for young learners.`,
  },
  {
    href: "#youtube",
    title: "YouTube",
    img: "/homeScreen/youtube-section.png",
    description: `Our YouTube Channel brings
extra educational fun with a
variety of videos, from story
telling to science experiments,
for both kids and parents.`,
  },
];

export default HashLink;
export { sections };
