import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "@mui/material";
import usePrivateRoute from "../../hooks/useAxiosPrivate";

const UsersTable = (props) => {
  const { updateUser, deleteUser, columns, allUsers, currPage } = props;
  const authRoute = usePrivateRoute();

  const exportTable = () => {
    authRoute
      .get(`/userinfo/export/?${props.title.toLowerCase()}`, {
        responseType: "blob",
      })
      .then((response) => {
        const filename = `ClubBabyEdu - ${props.title}`;

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.xlsx`);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-direction-row align-items-center">
        <h1>{props.title} List</h1>
        <button className="btn" onClick={exportTable}>
          <FontAwesomeIcon
            style={{
              fontSize: "24px",
            }}
            title="Export table"
            icon="fa-solid fa-file-export"
          />
        </button>
      </div>
      <table className="table table-borderless" id="users-table">
        <thead className="table-dark">
          <tr>
            {columns.map((item) => {
              return (
                <th key={item} className="text-center" scope="col">
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {allUsers
            ? allUsers.map((user, index) => {
                return (
                  <tr key={user._id}>
                    <th className="text-center" scope="row">
                      {index + 1 + (currPage - 1) * 20}
                    </th>
                    <td className="text-center">{user.name}</td>
                    <td className="text-center">{user.email}</td>
                    <td className="text-center capitalize">{user.type}</td>
                    <td className="text-center flexCenterXY">
                      <div
                        className={`${
                          user.premium
                            ? "premium-user-cell"
                            : "nonpremium-user-cell"
                        } premium-cell capitalize
                            `}
                      >
                        {user.premium.toString()}
                      </div>
                    </td>
                    <td className="text-center">
                      <FontAwesomeIcon
                        className="btn"
                        icon="fa-solid fa-pen"
                        onClick={() => updateUser(user)}
                      />
                      <FontAwesomeIcon
                        className="btn"
                        icon="fa-solid fa-trash"
                        style={{ color: "#f60101" }}
                        onClick={() => deleteUser(user)}
                      />
                    </td>
                  </tr>
                );
              })
            : Array.from({ length: 10 }, (_, index) => (
                <tr key={index}>
                  <td colSpan="100%">
                    <Skeleton height={50} />
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </>
  );
};

export default UsersTable;
