import ExcludeNavbar from "../components/ExcludeNavbar";

const TOS = () => {
  return (
    <div className="width100 flexColumn flexCenterXY">
      <div className="p-5 limitWidth">
        <ExcludeNavbar />
        <h1>ClubBabyEdu Terms Of Service</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel
          eros eu nisl volutpat luctus. Interdum et malesuada fames ac ante
          ipsum primis in faucibus. Proin non maximus arcu. Duis ut eros magna.
          In eget metus et nisi accumsan consectetur. Proin a nunc eget purus
          convallis rutrum eu a metus. Quisque id ligula a tortor vulputate
          efficitur in id mauris. Donec tristique pellentesque feugiat. Quisque
          ullamcorper felis sed odio ultrices, vitae tempus justo feugiat. Nunc
          in ex id felis congue rutrum eu vel enim. Curabitur mauris neque,
          interdum in nulla id, condimentum fermentum nunc. Mauris sit amet ex
          quis leo rutrum laoreet quis ac dui. Quisque ut varius est. Integer id
          erat eu leo fringilla laoreet quis ut justo. Morbi sit amet justo sed
          metus fermentum feugiat. Cras vel feugiat lectus, at mollis mauris.
          Nullam id rutrum est, ac auctor enim. Donec risus leo, posuere at
          massa vel, lobortis vestibulum felis. Integer nibh ex, sodales sed
          risus non, semper bibendum sapien. Nunc eu eros odio. Donec dignissim
          ultricies quam a accumsan. Sed ultrices tellus a mauris auctor
          vulputate. Donec imperdiet ipsum ut mi rhoncus, sed elementum nisl
          tempus. Suspendisse justo sem, volutpat vel nisi id, venenatis varius
          nisl. Nullam vel tellus et diam fringilla volutpat. Vivamus molestie
          convallis diam cursus bibendum. Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Fusce vel eros eu nisl volutpat luctus.
          Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin
          non maximus arcu. Duis ut eros magna. In eget metus et nisi accumsan
          consectetur. Proin a nunc eget purus convallis rutrum eu a metus.
          Quisque id ligula a tortor vulputate efficitur in id mauris. Donec
          tristique pellentesque feugiat. Quisque ullamcorper felis sed odio
          ultrices, vitae tempus justo feugiat. Nunc in ex id felis congue
          rutrum eu vel enim. Curabitur mauris neque, interdum in nulla id,
          condimentum fermentum nunc. Mauris sit amet ex quis leo rutrum laoreet
          quis ac dui. Quisque ut varius est. Integer id erat eu leo fringilla
          laoreet quis ut justo. Morbi sit amet justo sed metus fermentum
          feugiat. Cras vel feugiat lectus, at mollis mauris. Nullam id rutrum
          est, ac auctor enim. Donec risus leo, posuere at massa vel, lobortis
          vestibulum felis. Integer nibh ex, sodales sed risus non, semper
          bibendum sapien. Nunc eu eros odio. Donec dignissim ultricies quam a
          accumsan. Sed ultrices tellus a mauris auctor vulputate. Donec
          imperdiet ipsum ut mi rhoncus, sed elementum nisl tempus. Suspendisse
          justo sem, volutpat vel nisi id, venenatis varius nisl. Nullam vel
          tellus et diam fringilla volutpat. Vivamus molestie convallis diam
          cursus bibendum.
        </p>
      </div>
    </div>
  );
};

export default TOS;
