import { useEffect } from "react";

const Page = (props) => {
  useEffect(() => {
    document.title = `${props.title ?? ""}${
      (props.title && " | ") || ""
    }Club Baby Edu`;

    return () => {
      document.title = `Club Baby Edu`;
    };
  }, [props.title]);
  return props.children;
};

export default Page;
