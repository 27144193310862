import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles

const RichTextEditor = params => {
  const [text, setText] = useState(params.initial);

  const handleChange = (value) => {
    params.changeFunction(value);
    setText(value);
  };

  useEffect(() => {
    setText(params.initial);
  }, [params.initial]);

  return (
    <div>
      <ReactQuill
        value={text}
        onChange={handleChange}
        placeholder={params.placeholder ? params.placeholder : "Write something..."}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, 4 ,5 ,6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
        ]}
      />
    </div>
  );
};

export default RichTextEditor;