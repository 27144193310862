import { Link, useResolvedPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useState } from "react";
import { serverRoute } from "../../api/axios";
library.add(far, fas, fab);

const Footer = () => {
  const patha = useResolvedPath();
  const [newsletterEmail, setNewstellerEmail] = useState("");
  const [newsletterStatus, setNewsletterStatus] = useState("");
  const [newsletterLoading, setNewsletterLoading] = useState(false);

  const sendNewsLetter = async () => {
    setNewsletterStatus("");
    setNewsletterLoading(true);
    const res = await serverRoute.post("/newsletter", {
      email: newsletterEmail,
    });
    setNewsletterLoading(false);
    console.log("res", res.data);
    setNewstellerEmail("");
    setNewsletterStatus(res.data);
    setTimeout(() => {
      setNewsletterStatus("");
    }, 5000);
  };

  return (
    !patha.pathname.includes("/admin") &&
    !patha.pathname.includes("/games/play") && (
      <footer className="footer">
        <div>
          <Link to={"/"} className="d-flex brand">
            <img
              style={{
                padding: "0 20px",
              }}
              src="/logo.svg"
            />
          </Link>
          <div className="container-flex">
            <div className="section small-center">
              <h5>About Comapny</h5>
              <p>
                Welcome to the wondrous world of CLUB BABY, a delightfully
                unique line of high quality educational.
              </p>
              <div className="d-flex gap-15 pt-4">
                <div className="circular social flexCenterXY">
                  <FontAwesomeIcon
                    icon="fa-brands fa-x-twitter"
                    style={{ color: "white" }}
                  />
                </div>
                <div className="circular social flexCenterXY">
                  <FontAwesomeIcon
                    icon="fa-brands fa-facebook"
                    style={{ color: "white" }}
                  />
                </div>
                <a href="https://www.instagram.com/clubbabyworld/">
                  <div className="circular social flexCenterXY">
                    <FontAwesomeIcon
                      icon="fa-brands fa-instagram"
                      style={{ color: "white" }}
                    />
                  </div>
                </a>
              </div>
            </div>
            <div className="section">
              <h5>Quick Links</h5>
              <div className="d-flex flex-column gap-2">
                <a href="/games">Games</a>
                <a href="/worksheets">Worksheets</a>
                <a href="https://beyondt-shirts.com/pages/club-baby">
                  Merchandise
                </a>
                <a href="https://clubbaby.com/club-baby-music-store/?filter_by_type=collections&ordering_by=post_date">
                  Music Store
                </a>
              </div>
            </div>
            <div className="section">
              <h5>Useful Links</h5>
              <div className="d-flex flex-column gap-2">
                <a href="#">About Us</a>
                <a href="#">Privacy Policy</a>
                <a href="#">Contact Us</a>
                <a href="#">Terms Of Service</a>
              </div>
            </div>
            <div className="section small-center">
              <h5>Newsletter</h5>
              <div className="d-flex flex-column gap-2">
                <p>
                  Sign up for exclusive offers, monthly stories, and all things
                  Gentlemen's Box.
                </p>
                <input
                  className="form-control"
                  placeholder="Your email"
                  value={newsletterEmail}
                  onChange={(e) => {
                    setNewstellerEmail(e.target.value);
                  }}
                ></input>
                <button
                  className="btn"
                  style={{
                    backgroundColor: "#5980db",
                    color: "white",
                  }}
                  disabled={newsletterLoading}
                  onClick={() => {
                    sendNewsLetter();
                  }}
                >
                  Subscribe
                </button>
                <p
                  style={{
                    color: newsletterStatus.includes("success")
                      ? "lightGreen"
                      : "red",
                  }}
                >
                  {newsletterStatus}
                </p>
              </div>
            </div>
          </div>
          <hr className="opacity-100"></hr>
          <h6 className="text-center mb-0">
            @copyright 2024 all right reserved by Club Baby
          </h6>
        </div>
      </footer>
    )
  );
};

export default Footer;
