import { Link } from "react-router-dom";

const WorksheetCarouselItem = (props) => {
  const { text, img_path, search } = props.grade;

  return (
    <Link to={`/worksheets?grade=${search}`}>
      <div className="worksheet-home-card">
        <img
          style={{
            borderRadius: "10px",
          }}
          className="h-100 w-100"
          src={img_path}
        />
        <h5 className="my-2">{text}</h5>
        <div className="downloads">
          <h3 className="m-0">{props.count ? `${props.count}+` : 0}</h3>
          <p>worksheets</p>
        </div>
      </div>
    </Link>
  );
};

export default WorksheetCarouselItem;
