import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '@fortawesome/fontawesome-free'
import {library} from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import { useNavigate } from 'react-router-dom'
library.add(far,fas,fab);

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <div className="width100 flexRow">
            <div style={{width:"40px"}}></div>
            <div className='p-2' style={{
                cursor:"pointer",
                borderRadius:"15px",
                background:"white",
                width:"min-content",
                height:"min-content"
            }} onClick={() => {
                navigate(-1)
            }}>
                <FontAwesomeIcon icon={`fa-solid fa-chevron-left`} size='xl' style={{color:"black"}}/>

            </div>
        </div>
    )
}

export default BackButton