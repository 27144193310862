import { useModal } from "../context/LoginModalContext";

const LoginButton = () => {
  const { showModal } = useModal();

  return (
    <>
      <button
        className="login-btn btn-custom-join mt-3 mt-lg-0"
        onClick={() => {
          showModal("SIGNUP");
        }}
      >
        Join Free
      </button>
      <button
        className="login-btn btn-custom-login mt-3 mt-lg-0"
        onClick={() => {
          showModal("LOGIN");
        }}
      >
        Login
      </button>
    </>
  );
};

export default LoginButton;
