import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import { Pagination } from "@mui/material";
import Swal from "sweetalert2";
import UsersTable from "../components/UsersTable/UsersTable";
import EditUserModal from "../components/UsersTable/EditUserModal/EditUserModal";
import * as bootstrap from "bootstrap";

const UsersList = () => {
  const [allUsers, setAllUsers] = useState(null);
  const [currID, setCurrID] = useState(null);
  const [allUsersNot, setAllUsersNot] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [tempSearchTerm, setTempSearchTerm] = useState("");
  const [canNext, setCanNext] = useState(true);
  const [editedUser, setEditedUser] = useState(null);
  const [newUser, setNewUser] = useState(null);

  const authRoute = usePrivateRoute();
  const pages = Math.ceil(totalCount / 20);

  const handleChange = (event, value) => {
    setCurrPage(value);
  };

  useEffect(() => {
    if (newUser) {
      const index = allUsers.findIndex((user) => user._id === newUser._id);
      setAllUsers(allUsers.map((user, i) => (i === index ? newUser : user)));
    }
  }, [newUser]);

  useEffect(() => {
    setAllUsers(null);
    setLoadingUsers(true);
    authRoute
      .get(
        `/userinfo/all?page=${currPage}&filter=${filter}&search=${searchTerm}`
      )
      .then((data) => {
        setAllUsers(data.data.users);
        setTotalCount(data.data.totalCount);
        setLoadingUsers(false);
      });
  }, [currPage, filter, searchTerm]);

  const deleteUser = (user) => {
    Swal.fire({
      title: "Are you sure you want to delete this user?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute
          .delete("/userinfo", {
            data: {
              id: user._id,
            },
            headers: {
              "content-type": "application/json",
            },
          })
          .then((data) => {
            setAllUsers((prev) => {
              return prev.filter((el) => el._id != user._id);
            });
            Swal.fire({
              title: "Deleted!",
              text: "The user has been deleted successfully.",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              text: "The user was not deleted!",
              icon: "error",
            });
          });
      }
    });
  };

  const updateUser = (user) => {
    setEditedUser(user);
    showModal();
  };

  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("edit-user-modal")
    );
    myModal.show();
  };

  useEffect(() => {
    const myModalEl = document.getElementById("edit-user-modal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      setEditedUser(null);
    });
  }, []);

  const columns = ["ID", "Username", "Email", "Role", "Premium", "Action"];

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="flexCenterXY mx-5 pt-4">
          <div
            style={{
              width: "100%",
            }}
          >
            <UsersTable
              title="Users"
              updateUser={updateUser}
              deleteUser={deleteUser}
              columns={columns}
              allUsers={allUsers}
              currPage={currPage}
            />
            <EditUserModal
              roles={[
                "Teacher",
                "Student",
                "Parent",
                "Admin",
                "Super Admin",
                "Operator",
              ]}
              editedUser={editedUser}
              setNewUser={setNewUser}
            />
            {pages > 1 ? (
              <Pagination
                id="logs-pagination"
                count={pages}
                onChange={handleChange}
                page={currPage}
                shape="rounded"
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
