import React, { createContext, useState, useContext } from "react";
import * as bootstrap from "bootstrap";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("LOGIN");

  const closeModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("login-modal")
    );
    myModal.hide();
    setIsOpen(false);
  };

  const showModal = (type = null) => {
    if (type) {
      setModalType(type);
    }
    const myModal = new bootstrap.Modal(document.getElementById("login-modal"));
    myModal.show();
    setIsOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{ isOpen, modalType, setModalType, showModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
