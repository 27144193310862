import { useState } from "react"

const HelpButtonSticky = props => {

    let [helpOpen,setHelpOpen] = useState(false);

    return (
        <div className="helpCont">
            <div className="helpVideo" style={{display:helpOpen?"block":"none"}}>
                <iframe src={`${props.vidSrc}`} title="Instructions video" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            <div className="containerHelpBtn" onClick={() => {
                    setHelpOpen(prev => {
                        return !prev;
                    })
                }}>
                <div className="square"></div>
                <div className="text">How to play?</div>
            </div>
        </div>
    )
}

export default HelpButtonSticky