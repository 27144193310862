import "./GameItemsWrapper.css";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import GameItem from "../GameItem/GameItem";
import { splitCapitals } from "../../../scripts/helper";

const GameItemsWrapper = (props) => {
  const {
    gameName,
    totalCount,
    setCurrTotalCount,
    subjectChanged,
    editGameHandler,
    newGame,
  } = props;
  const [currPage, setCurrPage] = useState(1);
  const [games, setGames] = useState([]);
  const [isLoadingGames, setIsLoadingGames] = useState(true);

  const authRoute = usePrivateRoute();

  const pages = Math.ceil(totalCount / 10);

  const handleChange = (event, value) => {
    setCurrPage(value);
  };

  useEffect(() => {
    setIsLoadingGames(true);
    authRoute
      .get(
        `/games/allfast?subject=${gameName
          .split(" ")
          .join("")}&page=${currPage}`
      )
      .then((data) => {
        console.log(data.data.data);
        setGames(data.data.data);
        setIsLoadingGames(false);
      });
  }, [currPage]);

  const deleteGameHandler = (id) => {
    setGames((prev) => {
      let newPrev = [...prev];
      newPrev = newPrev.filter((game) => game._id !== id);
      return newPrev;
    });
    setCurrTotalCount({
      gameName,
      subjectCountFunc: (count) => count - 1,
      totalCountFunc: (count) => count - 1,
    });
  };

  useEffect(() => {
    if (newGame?.menuContext === gameName.split(" ").join("")) {
      const index = games.findIndex((game) => game._id === newGame._id);

      if (index !== -1) {
        setGames(games.map((game, i) => (i === index ? newGame : game)));
      } else {
        setGames([newGame, ...games]);

        if (!newGame.oldSubject) {
          setCurrTotalCount({
            gameName,
            subjectCountFunc: (count) => count + 1,
            totalCountFunc: (count) => count + 1,
          });
        } else {
          subjectChanged(
            splitCapitals(newGame.oldSubject),
            splitCapitals(newGame.menuContext)
          );
        }
      }
    } else if (newGame?.oldSubject == gameName.split(" ").join("")) {
      setGames((prev) => {
        let newPrev = [...prev];
        newPrev = newPrev.filter((game) => game._id !== newGame._id);
        return newPrev;
      });
    }
  }, [newGame]);

  return (
    <div id="pdfs-wrapper">
      {totalCount == 0 ? (
        <h4>No games to show</h4>
      ) : !isLoadingGames ? (
        <div>
          {games?.map((game) => {
            return (
              <GameItem
                deleteGameHandler={deleteGameHandler}
                key={game._id}
                game={game}
                editGame={editGameHandler}
              />
            );
          })}
          {pages > 1 ? (
            <Pagination
              id="games-pagination"
              count={pages}
              onChange={handleChange}
              page={currPage}
              shape="rounded"
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <h4>Loading games...</h4>
      )}
    </div>
  );
};

export default GameItemsWrapper;
