import { useEffect, useRef, useState } from "react";
import "./changePassContent.css";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { authRoute } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validatePassword } from "../../../scripts/validations";
import jwt_decode from "jwt-decode";
import { useModal } from "../../../context/LoginModalContext";
const LOGIN_URL = "/login";

const ChangePassContent = () => {
  const { setModalType, closeModal } = useModal();

  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [err, setErr] = useState("");
  const formRef = useRef(null);

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const clearFormData = () => {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
  };

  const validateForm = () => {
    console.log(newPassword);
    console.log(validatePassword(newPassword));
    console.log(newPassword);
    console.log(newPasswordConfirmation);
    console.log(newPassword !== newPasswordConfirmation);

    if (
      [currentPassword, newPassword, newPasswordConfirmation].some(
        (field) => field == ""
      )
    ) {
      setErr("Please fill out all fields");
    } else if (!validatePassword(newPassword)) {
      setErr("New password is not valid");
    } else if (newPassword !== newPasswordConfirmation) {
      setErr("New passwords don't match");
    } else {
      setErr("");
    }
    return false;
  };

  useEffect(() => {
    const modalElement = document.getElementById("change-pass-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
  };

  return (
    <div>
      <h3
        className="change-pass-modal-title my-3"
        style={{
          textAlign: "center",
        }}
      >
        Change Your Password
      </h3>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className="mx-5 mb-3 needs-validation"
        onSubmit={handlePasswordChange}
        ref={formRef}
        noValidate
      >
        <input
          type="password"
          name="old-password"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          className="form-control inputText mb-3"
          placeholder="Current Password"
        />
        <input
          type="password"
          name="new-password"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="New Password"
        />
        <input
          type="password"
          name="new-password-confirmation"
          value={newPasswordConfirmation}
          onChange={(e) => {
            setNewPasswordConfirmation(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Confirm New Password"
        />
        <label className="d-flex">
          <input
            onChange={(e) => {
              formRef.current
                .querySelectorAll("input.inputText")
                .forEach((input) => {
                  e.target.checked
                    ? input.removeAttribute("type")
                    : input.setAttribute("type", "password");
                });
            }}
            type="checkbox"
            name="checkbox"
            style={{
              marginRight: "5px",
            }}
          />
          Show passwords
        </label>
        <p
          style={{
            alignSelf: "center",
            color: "red",
            fontWeight: "600",
            marginTop: `${err ? "5px" : "0"}`,
          }}
        >
          {err}
        </p>
        <button
          className="btn btn-primary mt-3"
          style={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Bubblegum Sans",
            fontWeight: "normal",
            color: "white",
          }}
          type="submit"
        >
          Change
        </button>
      </form>
    </div>
  );
};

export default ChangePassContent;
