import {authRoute} from "../api/axios"
import useAuth from "./useAuth"

const useLogout = () => {
    const {setAuth} = useAuth();
    const logout = async () => {
        setAuth({});

        try{
            const response = await authRoute.get("/logout",{
                withCredentials:true
            })
        }catch(e){
            console.log(e);
        }
    }
    return logout;
}

export default useLogout;