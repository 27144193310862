let gameTypes = [
  {
    name: "Drag And Drop",
    img_path: "/userHome/dragAndDrop.jpeg",
    value: "DragAndDrop",
  },
  {
    name: "Circle Or Select",
    img_path: "/userHome/circleOrSelect.jpeg",
    value: "CircleOrSelect",
  },
  {
    name: "Counting",
    img_path: "/userHome/counting.jpeg",
    value: "Counting",
  },
  {
    name: "Tick Or Check",
    img_path: "/userHome/tickOrCheck.jpeg",
    value: "TickOrCheck",
  },
  {
    name: "Match Or Pair",
    img_path: "/userHome/matchOrPair.jpeg",
    value: "MatchOrPair",
  },
  {
    name: "Typing Complete",
    img_path: "/userHome/typingComplete.jpeg",
    value: "TypingComplete",
  },
  {
    name: "Coloring Fun",
    img_path: "/userHome/coloringFun.jpeg",
    value: "ColoringFun",
  },
  {
    name: "Reading Complete",
    img_path: "/userHome/readingComplete.jpeg",
    value: "ReadingComplete",
  },
];

export default { gameTypes };
