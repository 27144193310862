import React from "react";

const WorksheetPointer = (props) => {
  const { first, isLoading } = props;
  const zindex = props.zindex ?? 0;

  const colors = ["#5980db", "#f0b5b5", "#cbd2fa"];

  return (
    <div
      id={`worksheet-pointer-${zindex}`}
      className={`${first ? "first" : ""} ${isLoading ? "loading" : ""}`}
    >
      <style>{`
        div#worksheet-pointer-${zindex} {
          width: fit-content;
          height: 40px;
          position: relative;
          background: ${colors[zindex]};
          z-index: ${10 - zindex};
          align-content: center;
          font-weight: bold;
          text-decoration: underline;
        }

        div#worksheet-pointer-${zindex}:not(.first):not(.loading) > span {
          margin: 0 10px 0 30px;
        }

        div#worksheet-pointer-${zindex}.first > span {
          margin: 0 5px 0 10px;
        }

        div#worksheet-pointer-${zindex}:not(.first):after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 20px solid white;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
        }

        div#worksheet-pointer-${zindex}:before {
          content: "";
          position: absolute;
          right: -20px;
          bottom: 0;
          width: 0;
          height: 0;
          border-left: 20px solid ${colors[zindex]};
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
        }
      `}</style>
      <span className="clickable" onClick={props?.onClick}>
        {React.Children.map(props.children, (x) => x)}
      </span>
    </div>
  );
};

export default WorksheetPointer;
