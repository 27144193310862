import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../components/checkOut/checkOutForm";
import ExcludeNavbar from "../components/ExcludeNavbar";
const stripePromise = loadStripe(
  "pk_test_51Nj1prHBVpkHO6qhTuxI1N2C70aFbIf5AQIWEp632p1ZsqGUhwWLczKG1Ufq2ORIaykGSwlcUZll135Bjo4esVlf00z7szab3n"
);

const PaymentPage = () => {
  return (
    <div className="contAll payment backgroundFun width100 flexCenterXY flexColumn">
      <ExcludeNavbar />
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default PaymentPage;
