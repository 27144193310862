import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";

const Admin = () => {
  const authRoute = usePrivateRoute();
  let [stats, setStats] = useState({});
  useEffect(() => {
    authRoute.get(`/stats`).then((data) => {
      console.log(data.data);
      setStats(data.data);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="flexCenterXY">
          <div className="statCont">
            <div className="statBox">
              <h2>Number of users:</h2>
              <h3>{stats.amountOfUsers}</h3>
            </div>
            <div className="statBox">
              <h2>Number of premium users:</h2>
              <h3>{stats.amountOfPremiumUsers}</h3>
            </div>
            <div className="statBox">
              <h2>Number of games:</h2>
              <h3>{stats.amountOfGames}</h3>
            </div>
            <div className="statBox">
              <h2>Amount of PDFs</h2>
              <h3>{stats.amountOfPDFs}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Admin;
