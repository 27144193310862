import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

const History = () => {
  const [allLogs, setAllLogs] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [currPage, setCurrPage] = useState(1);

  const authRoute = usePrivateRoute();
  const pages = Math.ceil(totalCount / 20);

  const handleChange = (event, value) => {
    setCurrPage(value);
  };

  useEffect(() => {
    setAllLogs(null);
    setLoadingLogs(true);
    authRoute.get(`/history?page=${currPage}`).then((data) => {
      console.log(data.data);
      setAllLogs(data.data.data);
      setTotalCount(data.data.count);
      setLoadingLogs(false);
    });
  }, [currPage]);

  const columns = ["ip", "name", "email", "method", "domain", "url"];

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="flexCenterXY mx-5 pt-4">
          <div
            style={{
              width: "100%",
            }}
          >
            <h1>Admin Log History</h1>
            <table
              className="table table-borderless table-hover"
              id="users-table"
            >
              <thead className="table-dark">
                <tr>
                  {columns.map((item) => {
                    return (
                      <th
                        key={item}
                        className="text-center capitalize"
                        scope="col"
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {!loadingLogs && allLogs
                  ? allLogs.map((log, index) => {
                      return (
                        <tr
                          key={log._id}
                          title={moment(log.createdAt).format(
                            "MMMM Do YYYY, HH:mm:ss"
                          )}
                        >
                          {columns.map((col) => {
                            return <td className="text-center">{log[col]}</td>;
                          })}
                        </tr>
                      );
                    })
                  : Array.from({ length: 10 }, (_, index) => (
                      <tr key={index}>
                        <td colSpan="100%">
                          <Skeleton height={50} />
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            {pages > 1 ? (
              <Pagination
                id="logs-pagination"
                count={pages}
                onChange={handleChange}
                page={currPage}
                shape="rounded"
                className="mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default History;
