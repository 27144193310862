import axios from "axios";

//FOR LOCAL
// const BASE_AUTH_URL = "http://localhost:3002";
// const BASE_SERVER_URL = "http://localhost:3001";
// 
//FOR PROD
const BASE_AUTH_URL = "https://auth.clubbabyedu.com";
const BASE_SERVER_URL = "https://api.clubbabyedu.com";

//CHANGE BETWEEN LOCAL AND PROD ABOVE ^

export const serverRoute = axios.create({
    baseURL: BASE_SERVER_URL
})
export const authRoute = axios.create({
    baseURL: BASE_AUTH_URL
})
export const authPrivateRoute = axios.create({
    baseURL: BASE_AUTH_URL,
    headers:{
        'Content-Type':"application/json"
    },
    withCredentials:true
})
export const serverPrivateRoute = axios.create({
    baseURL: BASE_SERVER_URL,
    headers:{
        'test':'test',
        'content-type':"application/json"
    },
    withCredentials:true
})