import { useEffect, useState } from "react";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import AdminGameView from "../components/games/GameView/NewGameView";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";
import GameUploadModal from "../components/games/GameUploadModal/GameUploadModal";
import { useNavigate } from "react-router-dom";

const AdminGames = () => {
  const [newGame, setNewGame] = useState(null);
  const [editedGame, setEditedGame] = useState(null);

  const navigate = useNavigate();

  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-game-modal")
    );
    myModal.show();
  };

  const editGameHandler = (pdf) => {
    setEditedGame(pdf);
    showModal();
  };

  useEffect(() => {
    const myModalEl = document.getElementById("admin-game-modal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      setEditedGame(null);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "15px",
          }}
        >
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#845adf",
              color: "white",
              fontWeight: 700,
            }}
            onClick={showModal}
          >
            Add New Game
          </button>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#50ff50",
              color: "white",
              fontWeight: 700,
            }}
            onClick={() => {
              navigate("dev");
            }}
          >
            Game Dev Environment
          </button>
        </div>
        <GameUploadModal
          editedGame={editedGame}
          setNewGame={setNewGame}
        ></GameUploadModal>
        <AdminGameView
          editGameHandler={editGameHandler}
          newGame={newGame}
        ></AdminGameView>
      </div>
    </div>
  );
};

export default AdminGames;
