import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import moment from "moment";

const AdminPaymentHistory = () => {
  const [allUsers, setAllUsers] = useState(null);
  const [currID, setCurrID] = useState(null);
  const [currPage, setCurrPage] = useState(1);

  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute
      .get(`/userinfo/all?page=${currPage}&filter=&search=`)
      .then((data) => {
        console.log(data.data);
        setAllUsers(data.data.users);
      });
  }, [currPage]);

  const deleteUser = () => {
    authRoute
      .delete("/userinfo", {
        data: JSON.stringify({
          id: currID,
        }),
        headers: {
          "content-type": "application/json",
          test: "test",
        },
      })
      .then((data) => {
        setAllUsers((prev) => {
          return prev.filter((el) => el._id != currID);
        });
      });
  };

  const changePage = (howMuch) => {
    if (currPage + howMuch > 0) {
      setCurrPage(currPage + howMuch);
    }
  };

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="limitWidth mx-5">
          <table className="w-100">
            <thead>
              <tr>
                <th>ID</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Email Address</th>
              </tr>
            </thead>
            <tbody>
              {allUsers &&
                allUsers.map((el) => {
                  return (
                    <tr key={el._id}>
                      <td>{el._id}</td>
                      <td>{el.name}</td>
                      <td>{moment(Date.now()).format()}</td>
                      <td>{el.email}</td>
                      {/* <button onClick={() => {
                                        alert(JSON.stringify(el._id))
                                    }}>Delete</button> */}
                      {/* <td>
                        <button
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                          onClick={() => {
                            setCurrID(el._id);
                          }}
                        >
                          delete
                        </button>
                      </td> */}
                    </tr>
                  );
                })}
            </tbody>
            {allUsers && allUsers.length == 0 && <h1>No users found</h1>}
          </table>
          <div className="flexRow">
            <button
              onClick={() => {
                changePage(-1);
              }}
            >
              &larr;
            </button>
            <p>{currPage}</p>
            <button
              onClick={() => {
                changePage(1);
              }}
            >
              &rarr;
            </button>
          </div>
          <div
            className="modal fade"
            id="exampleModalLong"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Are you sure you want to delete?
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">This step is irreversible</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={() => {
                      deleteUser(currID);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPaymentHistory;
