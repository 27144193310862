import ExcludeNavbar from "../components/ExcludeNavbar";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";

const NotFound = ({ admin }) => {
  return admin ? (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content" className="flexCenterXY">
        <img
          style={{
            maxHeight: "100vh",
            width: "100%",
          }}
          src="/notFound.svg"
        ></img>
      </div>
    </div>
  ) : (
    <div>
      <ExcludeNavbar />
      <div className="flexCenterXY">
        <img
          style={{
            height: "100vh",
          }}
          src="/notFound.svg"
        ></img>
      </div>
    </div>
  );
};
export default NotFound;
