import { Link } from "react-router-dom";

const HeaderItem = (props) => {
  let textStyle = {
    textDecoration: eval(props.isFocused) ? "underline" : null,
  };
  return (
    <Link to={props.link} className="headerItem navItem">
      <h2 style={textStyle}>{props.value}</h2>
    </Link>
  );
};

export default HeaderItem;
