import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import jwt_decode from "jwt-decode"

const MembershipWrapper = () => {
    const {auth} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    return (
        auth?.accessToken 
            ? jwt_decode(auth?.accessToken).premium 
                ? <Navigate to="/profile" state={{from:location}} replace/>
                : <Outlet/> 
            : <Outlet/>)
}

export default MembershipWrapper;