import { useEffect, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import "./NewGameView.css";
import Filters from "../Filters";
import { Skeleton } from "@mui/material";
import GameItemsWrapper from "../GameItemsWrapper/GameItemsWrapper";

const AdminGameView = (props) => {
  const { editGameHandler, newGame } = props;

  const [subjectCount, setSubjectCount] = useState(null);

  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute.get(`/games/count`).then((data) => {
      const countsBySubject = data.data.countsBySubject;
      const subjects = Object.keys(countsBySubject).reduce((obj, type) => {
        const newType = type.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        obj[newType] = countsBySubject[type];
        return obj;
      }, {});
      setSubjectCount({
        totalCount: data.data.totalCount,
        countsBySubject: subjects,
      });
    });
  }, []);

  const setCurrTotalCount = ({
    gameName,
    subjectCountFunc = (number) => number,
    totalCountFunc = (number) => number,
  }) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [gameName]: subjectCountFunc(subjectCount.countsBySubject[gameName] ?? 0),
    };

    const newSubjectCount = {
      ...subjectCount,
      totalCount: totalCountFunc(subjectCount.totalCount),
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  const subjectChanged = (oldSubject, newSubject) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [oldSubject]: subjectCount.countsBySubject[oldSubject] - 1,
      [newSubject]: subjectCount.countsBySubject[newSubject] + 1,
    };

    const newSubjectCount = {
      ...subjectCount,
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  return (
    <div id="game-view-container">
      <div id="total-count">
        Total {subjectCount ? subjectCount.totalCount : 0} Games
      </div>
      {Filters.gameTypes.map((game, index) => {
        const currentID = `collapse${game.name.split(" ").join("")}`;
        const currentSubjectTextsID = `texts-${game.name.split(" ").join("")}`;
        const totalCount = subjectCount?.countsBySubject[game.name] ?? 0;

        return subjectCount ? (
          <div id="subject-button" key={game.name}>
            <div
              id={currentSubjectTextsID}
              key={index}
              value={game.name}
              className="game-collapse collapsed"
              data-toggle="collapse"
              data-target={"#" + currentID}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                }}
              >
                <img
                  style={{
                    height: "100px",
                    borderRadius: "20px",
                  }}
                  src={game.img_path}
                  alt=""
                ></img>
                <p
                  style={{
                    alignContent: "center",
                  }}
                  id="subject-name"
                >
                  {game.name}
                </p>
              </div>
              <div id="right-side-texts">
                <p id="subject-count">{totalCount}</p>
                <div id="game-chevron"></div>
              </div>
            </div>
            <div className="collapse" id={currentID}>
              <GameItemsWrapper
                setCurrTotalCount={setCurrTotalCount}
                totalCount={totalCount}
                gameName={game.name}
                subjectChanged={subjectChanged}
                editGameHandler={editGameHandler}
                newGame={newGame}
              />
            </div>
          </div>
        ) : (
          <Skeleton key={game.name} className="game-collapse-skeleton" />
        );
      })}
    </div>
  );
};

export default AdminGameView;
