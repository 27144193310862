const LoadingFrag = () => {
    return (
        <div className="loadingFrag">
            <h1>Loading...</h1>
            <div className="lds-ellipsis loadingGif"><div></div><div></div><div></div><div></div></div>

        </div>
    )
}

export default LoadingFrag;