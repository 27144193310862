import Rating from "@mui/material/Rating";
import { StarBorder } from "@mui/icons-material";

const TestimonialCarouselItem = (props) => {
  const { image, name, starNum, content } = props.testimonial;

  return (
    <div
      style={{
        backgroundColor: "white",
        color: "black",
        borderRadius: "15px",
        position: "relative",
        paddingTop: "50px",
        marginTop: "70px",
        height: "250px",
      }}
    >
      <div
        style={{
          width: "75px",
          height: "75px",
          position: "absolute",
          top: "-40px",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
        className="circular"
      >
        {!!Object.keys(props.testimonial).length && (
          <img
            className="circular w-100 h-100 object-fit-cover"
            src={image}
            alt="avatar"
          />
        )}
      </div>
      <div className="container d-flex flex-column text-center align-items-center gap-1">
        <h3>{name}</h3>
        {!!Object.keys(props.testimonial).length && (
          <Rating
            style={{
              color: "black",
            }}
            name="read-only"
            value={starNum}
            emptyIcon={
              <StarBorder style={{ color: "black" }} fontSize="inherit" />
            }
            readOnly
          />
        )}
        <p
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
          }}
          className="container fw-medium"
        >
          {content}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCarouselItem;
