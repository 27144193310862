import "./TestimonialView.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Rating from "@mui/material/Rating";
import { StarBorder } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TestimonialView = (props) => {
  const {
    testimonials,
    setSelectedItem,
    showModal,
    handleOrderChanged,
    deleteTestimonial,
  } = props;

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(testimonials);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    handleOrderChanged(newItems);
  };

  const openModal = (item) => {
    setSelectedItem(item);
    showModal();
  };

  return (
    <div id="testimonials-view-container">
      <div id="total-count">Total {testimonials?.length ?? 0} Testimonials</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="list"
              style={{
                width: "90%",
              }}
            >
              {testimonials
                ? testimonials.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => openModal(item)}
                          className="list-item"
                        >
                          <div className="d-flex gap-15">
                            <div
                              style={{
                                width: "150px",
                                height: "150px",
                                flexShrink: 0,
                              }}
                            >
                              <img
                                className="w-100 h-100 circular"
                                style={{
                                  display: "block",
                                  objectFit: "cover",
                                }}
                                src={item.image}
                              ></img>
                            </div>
                            <div className="d-flex flex-column flex-grow-1 gap-2">
                              <div className="d-flex justify-content-between">
                                <h5 className="m-0">{item.name}</h5>
                                <FontAwesomeIcon
                                  className="btn"
                                  icon="fa-solid fa-trash"
                                  style={{ color: "#f60101" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteTestimonial(item);
                                  }}
                                />
                              </div>
                              <Rating
                                style={{
                                  color: "black",
                                }}
                                name="read-only"
                                value={item.starNum}
                                emptyIcon={
                                  <StarBorder
                                    style={{ color: "black" }}
                                    fontSize="inherit"
                                  />
                                }
                                readOnly
                              />
                              <p>{item.content}</p>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))
                : Array.from({ length: 5 }, (_, index) => (
                    <Skeleton
                      className="list-item"
                      sx={{
                        transform: "none",
                        height: "186px",
                        top: "0",
                        left: "0",
                      }}
                    />
                  ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default TestimonialView;
