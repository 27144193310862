import { useEffect, useState } from "react";
import "./loginContent.css";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { authRoute } from "../../../api/axios";
import useAuth from "../../../hooks/useAuth";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateEmail, validatePassword } from "../../../scripts/validations";
import jwt_decode from "jwt-decode";
import { useModal } from "../../../context/LoginModalContext";
const LOGIN_URL = "/login";

const LoginContent = () => {
  const { setModalType, closeModal } = useModal();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToAdmin = (accessToken) => {
    const decodedToken = jwt_decode(accessToken);
    const adminTypes = ["Admin", "Super Admin", "Operator"];
    if (
      adminTypes.map((item) => item.toLowerCase()).includes(decodedToken.role)
    ) {
      navigate("/admin", { replace: true });
    }
  };

  const clearFormData = () => {
    setEmail("");
    setPassword("");
    setErr("");
  };

  useEffect(() => {
    const modalElement = document.getElementById("login-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  const googleLogin = async (cr) => {
    let theMail = jwtDecode(cr.credential).email;
    try {
      const res = await authRoute.post(
        "/login/google",
        {
          token: theMail,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      const accessToken = res?.data?.accessToken;
      setAuth({ theMail, accessToken });
      setPassword("");
      closeModal();
      navigateToAdmin(accessToken);
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 403) {
        setErr("User does not exist!");
      } else {
        setErr("Unexpected error");
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErr("");
    if (!validateEmail(email)) {
      setErr("Email is not valid!");
      return;
    }
    if (!validatePassword(password)) {
      setErr("Password is not valid!");
      return;
    }
    try {
      const res = await authRoute.post(
        LOGIN_URL,
        JSON.stringify({
          email: email,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      console.log(res.data);
      const accessToken = res?.data?.accessToken;
      console.log(accessToken);
      setAuth({ email, accessToken });
      setPassword("");
      setEmail("");
      closeModal();
      navigateToAdmin(accessToken);
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 403) {
        setErr("Incorrect password or username");
      } else {
        setErr("Unexpected error");
      }
    }
  };

  return (
    <div>
      <h3
        className="login-modal-title my-3"
        style={{
          textAlign: "center",
        }}
      >
        Login to Club Baby Edu
      </h3>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className="mx-5 mb-3"
        onSubmit={handleLogin}
      >
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className="inputText mb-3"
          placeholder="Password"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <label className="d-flex">
            <input
              type="checkbox"
              name="checkbox"
              value="value"
              style={{
                marginRight: "5px",
              }}
            />
            Remember me
          </label>
          <Link
            to="/reset-pass"
            style={{
              color: "blue",
              textDecoration: "underline",
            }}
            onClick={closeModal}
          >
            Forgot Password?
          </Link>
        </div>
        <p
          style={{
            alignSelf: "center",
            color: "red",
            fontWeight: "600",
            marginTop: `${err ? "5px" : "0"}`,
          }}
        >
          {err}
        </p>
        <button
          className="btn btn-primary mt-3"
          style={{
            color: "black",
            fontSize: "20px",
            fontFamily: "Bubblegum Sans",
            fontWeight: "normal",
            color: "white",
          }}
          type="submit"
        >
          Login
        </button>
      </form>
      <div
        id="sso-container"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <GoogleLogin
          onSuccess={(cr) => {
            googleLogin(cr);
          }}
          onError={() => console.log("Login Failed")}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px 0",
        }}
      >
        Don't have an account?
        <div
          className="clickable"
          style={{
            color: "black",
            textDecoration: "underline",
            marginLeft: "5px",
            fontWeight: "bold",
          }}
          onClick={() => {
            setModalType("SIGNUP");
          }}
        >
          Sign Up
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
