const PricingCont = (props) => {
  return (
    <div className="pricing-container">
      <h3>{props.period}</h3>
      <div className="content">
        <div className="info">
          <div
            className="d-flex gap-2"
            style={{
              alignItems: "center",
            }}
          >
            <h3>$ {props.price}</h3>
            <h4>per {props.noun}</h4>
          </div>
          <p>{props.description}</p>
        </div>
        <button className="btn btn-primary">Buy now</button>
      </div>
    </div>
  );
};
export default PricingCont;
