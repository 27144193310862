import ExcludeNavbar from "../components/ExcludeNavbar";
import ActualPassForm from "../components/login/ActualPassForm";

const PasswordResetPage = () => {
  return (
    <div>
      <ExcludeNavbar />
      <ActualPassForm />
    </div>
  );
};

export default PasswordResetPage;
