import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";
import useLogout from "../hooks/useLogout";
import { useState } from "react";
import { useEffect } from "react";
import { useUserImage } from "../context/UserImageContext";

const HomeBtn = () => {
  const { auth } = useAuth();
  const { userImage } = useUserImage();
  const logout = useLogout();
  const navigate = useNavigate();
  const [decodedData, setDecodedData] = useState(null);

  useEffect(() => {
    if (auth && auth.accessToken) {
      setDecodedData(jwt_decode(auth.accessToken));
    }
  }, [auth]);

  const myFunction = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  };

  window.onclick = (event) => {
    if (!event.target.matches(".dropbtn")) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  return (
    <div className="dropdown mt-3 mt-lg-0">
      <div
        style={{
          width: "60px",
          height: "60px",
        }}
        className="circular"
      >
        <img
          className="circular w-100 h-100 object-fit-cover dropbtn clickable"
          src={userImage}
          alt="avatar"
          onClick={myFunction}
        />
      </div>
      <div id="myDropdown" className="dropdown-content">
        <span
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          onClick={() => {
            navigate("/profile", { replace: true });
          }}
        >
          <i className="fa-regular fa-circle-user"></i> Profile
        </span>
        <span
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          onClick={() => {
            logout();
          }}
        >
          <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
        </span>
      </div>
      {/*
        fun-emoji
        personas
        avataaars
      */}
    </div>
  );
};

export default HomeBtn;
