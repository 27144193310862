import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewWorkbookItem = (props) => {
  return (
    <div
      className={`flexColumn p-3 workbook-item ${
        props.small ? "related-pdf" : "col-md-6 col-lg-3"
      }`}
    >
      <div className="image-container" onClick={props.onClick}>
        <img className="img-unclickable" src={props.image} alt="" />
        {props.premium && (
          <div className="worksheet-premium-container">
            <FontAwesomeIcon
              title="Premium"
              icon="fa-solid fa-crown"
              className="crown"
            />
          </div>
        )}
        <div className="image-hover">
          <h3 className="image-text">{props.name}</h3>
          <span
            className="image-description"
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default NewWorkbookItem;
