import { loadStripe } from "@stripe/stripe-js";
import ExcludeNavbar from "../components/ExcludeNavbar";
import useRefreshToken from "../hooks/useRefreshToken";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const stripePromise = loadStripe(
  "pk_test_51Nj1prHBVpkHO6qhTuxI1N2C70aFbIf5AQIWEp632p1ZsqGUhwWLczKG1Ufq2ORIaykGSwlcUZll135Bjo4esVlf00z7szab3n"
);

const PaymentPageSuccess = () => {
  const refresh = useRefreshToken();
  
  async function refr() {
    await refresh();
  }

  useEffect(() => {
    refr();
  }, []);

  return (
    <div className="contAll payment backgroundFun width100 flexCenterXY">
      <ExcludeNavbar />
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
          padding: "15px",
        }}
      >
        <h3>Success!</h3>
        <div className="wrapper">
          {" "}
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            {" "}
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />{" "}
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <Link to="/">
          <button
            className="siteButton"
            style={{
              background: "white",
              color: "black",
              border: "1px solid black",
            }}
          >
            Enjoy ClubBabyEdu!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default PaymentPageSuccess;
