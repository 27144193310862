import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./GameItem.css";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import jwt_decode from "jwt-decode";

const GameItem = (props) => {
  const { game, deleteGameHandler, editGame } = props;

  const authRoute = usePrivateRoute();

  const deleteGame = () => {
    Swal.fire({
      title: "Are you sure you want to delete this game?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute.delete(`/game/${game._id}`).then((response) => {
          Swal.fire({
            title: "Deleted!",
            text: "The game has been deleted successfully.",
            icon: "success",
          });
          deleteGameHandler(game._id);
        });
      }
    });
  };

  const { auth } = useAuth();
  let decodedData = null;

  if (auth) {
    if (auth.accessToken) {
      decodedData = jwt_decode(auth.accessToken);
    }
  } else {
    decodedData = null;
  }

  return (
    <div id="pdf-container">
      <div id="pdf-item">
        <p id="pdf-name">{game.name}</p>
        <div id="pdf-item-icons">
          <FontAwesomeIcon
            className="btn"
            icon="fa-solid fa-pen"
            onClick={() => editGame(game)}
          />
          {decodedData?.id === "66029319badd5b87eb43c71c" && (
            <FontAwesomeIcon
              className="btn"
              icon="fa-solid fa-trash"
              onClick={deleteGame}
              style={{ color: "#f60101" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default GameItem;
