// import {PaymentElement} from '@stripe/react-stripe-js';

import { AddressElement, CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import usePrivateRoute from "../../hooks/useAxiosPrivate";
import { serverRoute } from "../../api/axios";
import { useState } from "react";
import { Link,Navigate,useNavigate,useSearchParams } from "react-router-dom";
import useRefreshToken from "../../hooks/useRefreshToken";

const CheckoutForm = props => {
    const stripe = useStripe();
    const elements = useElements();
    const refresh = useRefreshToken();
    const navigate = useNavigate();
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [processingPayment,setProcessingPayment] = useState(false);
    // const lessPrivateRoute = myAxios.
    const privateRoute = usePrivateRoute();
    const [searchParams, setSearchParams] = useSearchParams();
    let sub_inf = searchParams.get('sub');
    // const PRODUCT_ID = sub_inf == 'Monthly' ? "price_1NkvlEHBVpkHO6qhwnuxb8Xi" : "price_1NkvlEHBVpkHO6qhAZk9xj45";
    const PRODUCT_ID = sub_inf == 'Monthly' ? "price_1OPBhcHBVpkHO6qhz5SuV9vV" : "price_1OPBhcHBVpkHO6qhdPkMO72j";
    console.log(searchParams.get('sub'));
    const createSubscription = async () => {
        setError("");
        try{
            setProcessingPayment(true);
            const paymentMethod = await stripe.createPaymentMethod({
                card:elements.getElement(CardNumberElement),
                type:'card',
            });
            console.log(JSON.stringify(paymentMethod));
            if(paymentMethod.error){
                setError(paymentMethod.error.message)
                setProcessingPayment(false);
                return;
            }
            let res;
            try{
                res = await privateRoute.post("/create-subscription",
                    JSON.stringify({
                        paymentMethod:paymentMethod.paymentMethod.id,
                        priceId:PRODUCT_ID
                    }),
                {
    
                });

            }catch(e){
                console.log(e.response.status + "g");
                if(e.response.status == 400){
                    setProcessingPayment(false)
                    return setError("Card declined!")
                }
                return;

            }
            console.log(res.data);
            const data = res.data;
            const confirm = await stripe.confirmCardPayment(data.clientSecret);
            if(confirm.error){
                console.log(confirm.error);
                setError(confirm.error.message)
                setProcessingPayment(false);
                return;
            }
            setProcessingPayment(false);
            
            setSuccess(true)
            // navigate("/membership/pay/success")
            // alert("payment success!")
        }catch(e){
            console.log(e);
            setProcessingPayment(false);
            // alert("payment failed!")
        }
    }
    const appearance = {
        theme: 'stripe'
      };
      
    return (
        <div>
            <div className="card-form">
                {!success &&
                <div className="card-element">
                    <h3 className="mb-3">Subscription Payment</h3>
                    <p className="mb-3">Full access to ClubBabyEdu</p>
                    <CardNumberElement/>
                    <div style={{height:"15px"}}></div>
                    <CardExpiryElement/>
                    <div style={{height:"15px"}}></div>
                    <CardCvcElement/>
                    <div style={{height:"15px"}}></div>
                    {error &&
                    (<><p style={{color:'red'}} className="errorTxt">{error}</p>
                    <div style={{height:"15px"}}></div></>)        
                    }
                    <button disabled={processingPayment} onClick={createSubscription}>
                        <div className="flexRow width100 flexCenterXY">
                            Subscribe - {sub_inf == "Monthly" ? "$5/month" : "$47.88/year"}
                            <div className="lds-ring" style={{display:`${processingPayment ? "block" : "none"}`}}><div></div><div></div><div></div><div></div></div>
                        </div>
                    </button>
                </div>
                }
                {success && 
                <Navigate to="/membership/pay/success"/>
                }
            </div>
        </div>
    );
};

export default CheckoutForm;