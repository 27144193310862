import { authRoute } from "../api/axios";
import useAuth from "./useAuth";
import useLogout from "./useLogout";

const useRefreshToken = () => {
  const { setAuth } = useAuth();
  const logOut = useLogout();
  
  const refresh = async () => {
    try {
      const res = await authRoute.get("/refresh", {
        withCredentials: true,
      });
      setAuth((prev) => {
        return { ...prev, accessToken: res.data.accessToken };
      });
      localStorage.setItem("accessToken", res.data.accessToken);
      return res.data.accessToken;
    } catch {
      logOut();
    }
  };

  return refresh;
};

export default useRefreshToken;
