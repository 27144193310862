import { Link } from "react-router-dom";

const GameCarouselItem = (props) => {
  const { img_path, name, value } = props.gameType;

  return (
    <Link to={`/games/${name.split(" ").join("")}`}>
      <img
        style={{
          borderRadius: "15px",
          boxShadow: "5px 5px 20px 0px rgba(0, 0, 0, 0.2)",
        }}
        className="h-100 w-100"
        src={img_path}
      />
    </Link>
  );
};

export default GameCarouselItem;
