const StarsGroup = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40 50C39.6139 48.8737 39.2278 47.7474 38.8467 46.6192C39.8014 46.1418 40.7219 45.5366 41.5857 44.8497C41.7428 44.7219 41.6507 44.4594 41.4501 44.4626C40.1866 44.4821 38.9232 44.5122 37.6597 44.5436C37.5504 43.4007 37.3428 42.2689 37.0957 41.1587C37.0385 40.9652 36.7755 40.9906 36.6992 41.1494C36.4461 41.7437 36.1929 42.3379 35.9398 42.9319C35.7431 43.3912 35.5334 43.855 35.4352 44.3435C34.4798 43.9792 33.3028 44.0861 32.1214 44.2784C31.9403 44.315 31.92 44.5674 32.0778 44.6644C32.9114 45.2437 33.7857 45.7428 34.7006 46.1518C34.3241 47.2455 34.0236 48.355 33.7992 49.4959C33.7482 49.7775 33.9715 49.9631 34.2006 49.8669C35.3382 49.3747 36.3923 48.7675 37.3361 48.0239C37.9611 48.6611 38.5857 49.2988 39.2105 49.9356C39.3803 50.1086 39.7539 49.9624 39.6639 49.7124Z"
        fill="white"
      />

      <path
        d="M70 40C69.2268 37.7542 68.4535 35.5084 67.6932 33.2589C69.6028 32.1965 71.4056 30.9271 73.0428 29.4744C73.3519 29.1977 73.1452 28.6323 72.7252 28.6391C70.1832 28.6799 67.6416 28.7433 65.0994 28.8094C64.8391 26.6207 64.4141 24.4594 63.8391 22.3175C63.7117 21.8469 63.1469 21.9055 62.9742 22.2992C62.4231 23.5498 61.872 24.8004 61.321 26.0507C60.8972 27.0117 60.4399 27.9783 60.2168 29.0073C58.2548 28.2026 55.7274 28.4672 53.6808 28.8684C53.2889 28.9467 53.2466 29.4743 53.5531 29.678C55.3844 30.8933 57.3091 31.9218 59.3239 32.7944C58.5506 35.0968 57.9322 37.4328 57.4781 39.8021C57.3714 40.3578 57.8577 40.7273 58.3671 40.5244C60.7776 39.5627 63.0024 38.2962 64.9624 36.5807C66.3986 37.9298 67.8347 39.28 69.2739 40.6259C69.6397 40.9704 70.449 40.6831 70.2341 40.1124Z"
        fill="white"
        transform="rotate(30 70 40)"
      />

      <path
        d="M55 70C54.3827 68.256 53.7655 66.512 53.1604 64.7635C54.7634 63.9153 56.2915 62.8966 57.7 61.713C57.9525 61.4956 57.7855 61.041 57.4535 61.0466C55.4855 61.0784 53.5176 61.1274 51.5492 61.1784C51.3425 59.4174 51.0129 57.6805 50.5772 55.976C50.4739 55.619 50.0331 55.6647 49.8998 55.9608C49.4788 56.9276 49.0578 57.8944 48.637 58.8609C48.3102 59.6013 47.9634 60.3492 47.8458 61.1436C46.292 60.5321 44.3014 60.7324 42.7196 61.0258C42.4205 61.0849 42.3879 61.4786 42.6211 61.63C44.0569 62.5341 45.5807 63.3032 47.1756 63.9308C46.5808 65.6784 46.1061 67.4544 45.7618 69.2764C45.6783 69.6924 46.0547 69.972 46.4464 69.8195C48.3092 69.1003 50.0186 68.1319 51.4626 66.8605C52.5775 67.8628 53.6916 68.8659 54.8076 69.8664C55.0866 70.1233 55.6915 69.9045 55.5253 69.4768Z"
        fill="white"
        transform="rotate(-15 55 70)"
      />
    </svg>
  );
};

export default StarsGroup;
