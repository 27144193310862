import React, { createContext, useState, useContext, useEffect } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import jwt_decode from "jwt-decode";
import useAuth from "../hooks/useAuth";

const UserImageContext = createContext();

export const UserImageProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userImage, setUserImage] = useState(
    "https://miro.medium.com/v2/resize:fit:1260/1*ngNzwrRBDElDnf2CLF_Rbg.gif"
  );
  const [response, setResponse] = useState();
  const [decodedData, setDecodedData] = useState(null);
  const { auth } = useAuth();
  const authRoute = usePrivateRoute();

  useEffect(() => {
    if (auth && auth.accessToken) {
      setDecodedData(jwt_decode(auth.accessToken));
    } else {
      setIsLoading(true);
      setDecodedData(null);
      setUserImage(
        "https://miro.medium.com/v2/resize:fit:1260/1*ngNzwrRBDElDnf2CLF_Rbg.gif"
      );
      setResponse(null);
    }
  }, [auth]);

  useEffect(() => {
    if (auth && auth.accessToken) {
      authRoute
        .get("/userinfo")
        .then((data) => {
          setResponse(data.data);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }, [auth]);

  useEffect(() => {
    if (!isLoading) {
      if (decodedData && response) {
        setUserImage(
          response.picture
            ? response.picture
            : `https://api.dicebear.com/9.x/initials/svg?seed=${
                decodedData?.name ?? ""
              }`
        );
      } else if (decodedData) {
        setUserImage(
          `https://api.dicebear.com/9.x/initials/svg?seed=${
            decodedData?.name ?? ""
          }`
        );
      }
    }
  }, [isLoading, decodedData]);

  const editUserImage = (image) => {
    setUserImage(
      image ??
        `https://api.dicebear.com/9.x/initials/svg?seed=${
          decodedData?.name ?? ""
        }`
    );
  };

  return (
    <UserImageContext.Provider value={{ userImage, editUserImage }}>
      {children}
    </UserImageContext.Provider>
  );
};

export const useUserImage = () => useContext(UserImageContext);
