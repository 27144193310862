import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WorksheetStats = (props) => {
  const { icon, text } = props;

  return (
    <div
      style={{
        background: "#d0d0d0",
        height: "fit-content",
        padding: "5px 10px",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          background: "#5980DB",
          padding: "5px",
          borderRadius: "50px",
          marginLeft: "-5px",
          color: "white",
        }}
      />
      <span
        className="ml-2 text-black"
        style={{
          padding: "0 5px",
          fontWeight: "bold",
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default WorksheetStats;
