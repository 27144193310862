import {serverPrivateRoute} from "../api/axios"
import { useEffect } from "react"
import useRefreshToken from "./useRefreshToken"
import useAuth from "./useAuth"
import useLogout from "./useLogout"

const usePrivateRoute = () => {
    const refresh = useRefreshToken();
    const {auth} = useAuth();
    const logout = useLogout();
    
    useEffect(() => {

        const requestIntercept = serverPrivateRoute.interceptors.request.use(
            config => {
                if(!config.headers['Authorization']){
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config
            },(error) => Promise.reject(error)
        )

        const responseIntercept = serverPrivateRoute.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if(error?.response?.status === 403 && !prevRequest?.sent){
                    prevRequest.sent = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return serverPrivateRoute(prevRequest);
                }else{
                    // logout();
                }
                return Promise.reject(error)
            }
        );
        return () => {
            serverPrivateRoute.interceptors.response.eject(responseIntercept)
            serverPrivateRoute.interceptors.request.eject(requestIntercept)
        }
    },[auth,refresh])

    return serverPrivateRoute;
}

export default usePrivateRoute