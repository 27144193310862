import "./TestimonialUploadModal.css";
import { useEffect, useRef, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";
import Rating from "@mui/material/Rating";
import { StarBorder } from "@mui/icons-material";

const TestimonialUploadModal = (props) => {
  const { editedTestimonial, setNewTestimonial } = props;
  const [type, setType] = useState("ADD");
  const [isUploading, setIsUploading] = useState(false);

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [starNum, setStarNum] = useState(0);
  const [image, setImage] = useState(null);
  const imageInputRef = useRef(null);

  const authRoute = usePrivateRoute();

  const createTestimonialObject = () => {
    return {
      name,
      image,
      content,
      starNum,
    };
  };

  const closeModal = () => {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("admin-testimonial-modal")
    );
    modal.hide();
  };

  const config = {
    EDIT: {
      title: "EDIT TESTIMONIAL",
      cancelBtn: "Close",
      submitBtn: "Save",
      submitBtnSubmitting: "Saving...",
    },
    ADD: {
      title: "ADD NEW TESTIMONIAL",
      cancelBtn: "Clear",
      submitBtn: "Submit",
      submitBtnSubmitting: "Submitting...",
    },
  };

  const initEditedValues = () => {
    console.log(editedTestimonial);

    setName(editedTestimonial.name);
    setContent(editedTestimonial.content);
    setStarNum(editedTestimonial.starNum);
    setImage(editedTestimonial.image);
  };

  useEffect(() => {
    console.log(editedTestimonial, editedTestimonial ? "EDIT" : "ADD");

    setType(editedTestimonial ? "EDIT" : "ADD");
  }, [editedTestimonial]);

  useEffect(() => {
    console.log(type);

    (({ EDIT: initEditedValues, ADD: clearFormData })[type] || (() => {}))();
  }, [type]);

  const handleGameUpdate = async () => {
    Swal.fire({
      title: "Are you sure you want to save your changes?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsUploading(true);
        authRoute
          .patch(
            `/testimonials/${editedTestimonial._id}`,
            createTestimonialObject()
          )
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The testimonial has been updated successfully.",
              icon: "success",
            });
            setNewTestimonial(response.data);
            setIsUploading(false);
            closeModal();
          })
          .catch((error) => {
            console.error("Error updating file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
            setIsUploading(false);
          });
      }
    });
  };

  const handleGameUpload = async () => {
    Swal.fire({
      title: "Uploading!",
      html: "It might take some time...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setIsUploading(true);
    try {
      const response = await authRoute.post(
        `/testimonials`,
        createTestimonialObject()
      );

      setNewTestimonial(response.data);

      console.log("File uploaded successfully");
      Swal.fire({
        title: "Success!",
        text: "Testimonial uploaded successfully!",
        icon: "success",
        confirmButtonText: "Keep uploading",
      }).then((result) => {
        if (result.isConfirmed) {
          clearFormData();
        }
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
    setIsUploading(false);
  };

  const clearFormData = () => {
    setName("");
    setContent("");
    setStarNum(0);
    setImage(null);
    imageInputRef.current.value = "";
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const modalElement = document.getElementById("admin-testimonial-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  useEffect(() => {
    const myModal = new bootstrap.Modal(
      document.getElementById("admin-testimonial-modal")
    );

    const closeButton = document.querySelector(".modal-close-button");
    closeButton.addEventListener("click", () => {
      myModal.hide();
    });
  }, []);

  const handleCancelButton = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-testimonial-modal")
    );

    type == "EDIT" ? myModal.hide() : clearFormData();
  };

  return (
    <div
      className="modal fade"
      id="admin-testimonial-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="admin-testimonial-modal-label"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-50"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="admin-testimonial-modal-label">
              {config[type].title}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="btn-close btn-close-white modal-close-button"
              {...(isUploading ? { tabIndex: -1 } : {})}
            ></button>
          </div>
          <div className="modal-body pb-0">
            <form>
              <div className="form-floating mb-3">
                <input
                  id="game-name-input"
                  className="form-control"
                  placeholder=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-name-input">Name</label>
              </div>
              <div
                className="mb-3 d-flex"
                style={{
                  gap: "20px",
                }}
              >
                <div className="w-50">
                  <input
                    className="form-control"
                    id="image-cover"
                    accept="image/*"
                    type="file"
                    ref={imageInputRef}
                    onChange={(e) => handleImageChange(e)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  />
                </div>
                <div className="w-50 d-flex justify-content-center align-items-center">
                  <Rating
                    style={{
                      color: "black",
                    }}
                    value={starNum}
                    onChange={(e, v) => {
                      setStarNum(v);
                    }}
                    emptyIcon={
                      <StarBorder
                        style={{ color: "black" }}
                        fontSize="inherit"
                      />
                    }
                  />
                </div>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  id="game-content-input"
                  className="form-control"
                  placeholder=""
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="game-content-input">Content</label>
              </div>
            </form>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-danger modal-cancel-button"
              id="modal-cancel-button"
              onClick={handleCancelButton}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {config[type].cancelBtn}
            </button>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#845adf",
                color: "white",
              }}
              onClick={editedTestimonial ? handleGameUpdate : handleGameUpload}
              disabled={isUploading}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {isUploading
                ? config[type].submitBtnSubmitting
                : config[type].submitBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialUploadModal;
