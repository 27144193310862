import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import useLogout from "../hooks/useLogout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import useRefreshToken from "../hooks/useRefreshToken";
import { useRef } from "react";
import useAuth from "../hooks/useAuth";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";
import moment from "moment";
import { useUserImage } from "../context/UserImageContext";
import ChangePassModal from "../components/changePassModal/changePassModal";
library.add(far, fas, fab);

const User = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [userToUpdate, setUserToUpdate] = useState();
  const authRoute = usePrivateRoute();
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingResume, setResumeCancel] = useState(false);
  const [cancelError, setCancelError] = useState("");
  const [resumeError, setResumeError] = useState("");
  const [cancelButton, setCancelButton] = useState(false);
  const [resumeButton, setResumeButton] = useState(false);
  const refresh = useRefreshToken();
  const [subStat, setSubStat] = useState("");
  const [editButtonIndex, setEditButtonIndex] = useState(0);
  const logout = useLogout();
  const formRef = useRef(null);
  const { auth, setAuth } = useAuth();
  const [error, setError] = useState(false);
  const imageInputRef = useRef(null);
  const { userImage, editUserImage } = useUserImage();

  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState("LOGIN");

  const closeModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("change-pass-modal")
    );
    myModal.hide();
    setIsOpen(false);
  };

  const showModal = (type = null) => {
    if (type) {
      setModalType(type);
    }
    const myModal = new bootstrap.Modal(
      document.getElementById("change-pass-modal")
    );
    myModal.show();
    setIsOpen(true);
  };

  const saveEditButton = [
    { name: "Edit", color: "warning" },
    { name: "Save", color: "success" },
  ];

  useEffect(() => {
    authRoute
      .get("/userinfo")
      .then((data) => {
        setIsLoading(false);
        const momentDate = moment(data.data?.age ?? "");
        data.data.age = momentDate.isValid()
          ? momentDate.format("YYYY-MM-DD")
          : undefined;

        setUserInfo(data.data);
        setUserToUpdate(data.data);
        if (
          data.data.premium &&
          data.data.subStatus?.split(".")[0] == "Subscribed"
        ) {
          setCancelButton(true);
        }
        if (
          data.data.premium &&
          data.data.subStatus?.split(".")[0] == "Canceled"
        ) {
          setResumeButton(true);
        }
        if (data.data?.subStatus) {
          setSubStat(data.data.subStatus);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(true);
      });
  }, []);

  const updateUser = () => {
    setIsSaving(true);

    authRoute
      .patch(`/userinfo/edit/${userInfo._id}`, userToUpdate, {
        "content-type": "application/json",
      })
      .then((response) => {
        Swal.fire({
          title: "Updated!",
          text: "The user has been updated successfully.",
          icon: "success",
        });

        setUserInfo(userToUpdate);
        const JWTParts = auth.accessToken.split(".");
        JWTParts[1] = JSON.parse(atob(JWTParts[1]));
        JWTParts[1] = { ...JWTParts[1], ...userToUpdate };
        JWTParts[1] = btoa(JSON.stringify(JWTParts[1]));
        setAuth({
          email: userToUpdate.email,
          accessToken: JWTParts.join("."),
        });
        setEditButtonIndex(+!editButtonIndex);
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        Swal.fire({
          title: "Error!",
          html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
          icon: "error",
          confirmButtonText: "Try again",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const resumeSubscription = () => {};

  const cancelSubscription = () => {
    setLoadingCancel(true);
    setCancelError("");

    try {
      authRoute
        .post("/cancelSubscription")
        .then(async (data) => {
          setSubStat(
            `Canceled. Subscription will end on ${
              subStat.split(" ")[3]
            } (until then you can access premium content)`
          );
          setCancelButton(false);
          setResumeButton(true);
          setLoadingCancel(false);
        })
        .catch((e) => {
          setCancelError("An error has occured!");
          setLoadingCancel(false);
        });
    } catch {
      console.log("caught err");
      setCancelError("An error has occured!");
      setLoadingCancel(false);
    }
  };

  const handleEditClick = () => {
    imageInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;

        authRoute
          .put(
            `/userinfo/${userInfo._id}/picture`,
            {
              picture: base64Image,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The profile picture has been updated successfully.",
              icon: "success",
            });
            editUserImage(base64Image);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageRemove = () => {
    authRoute
      .put(
        `/userinfo/${userInfo._id}/picture`,
        {
          picture: null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        Swal.fire({
          title: "Updated!",
          text: "The profile picture has been removed successfully.",
          icon: "success",
        });
        editUserImage(null);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        Swal.fire({
          title: "Error!",
          html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
          icon: "error",
          confirmButtonText: "Try again",
        });
      });
  };

  return (
    <div className="mt-3 min-vh-100 d-flex flex-column gap-15 user-page-content">
      <ChangePassModal modalType={modalType} closeModal={closeModal} />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="fw-bolder">Account Setting</h3>
        <button
          className="btn"
          title="Logout"
          onClick={() => {
            logout();
          }}
        >
          <FontAwesomeIcon
            icon="fa-solid fa-arrow-right-from-bracket"
            style={{
              fontSize: "30px",
            }}
          />
        </button>
      </div>
      <div className="d-flex gap-15">
        <div
          style={{
            width: "100px",
            height: "100px",
            position: "relative",
            overflow: "hidden",
          }}
          className="profile-pic-edit circular"
        >
          <img
            className="circular w-100 h-100 object-fit-cover"
            src={userImage}
            alt="avatar"
          />
          <div
            className="edit circular"
            style={{
              gap: "5px",
            }}
          >
            <input
              className="d-none"
              type="file"
              ref={imageInputRef}
              onChange={handleImageChange}
              accept="image/*"
            ></input>
            <button
              className="btn edit-image-button"
              onClick={handleEditClick}
              title="Edit profile picture"
            >
              <i className="fa fa-pencil fa-lg"></i>
            </button>
            <button
              className="btn edit-image-button"
              onClick={handleImageRemove}
              style={{
                color: "red",
              }}
              title="Remove profile picture"
            >
              <i className="fa fa-trash fa-lg"></i>
            </button>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <h5>{userInfo?.name}</h5>
          <h6>{userInfo?.email}</h6>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-6">
          <div className="d-flex gap-15 align-items-center">
            <hr className="flex-grow-1" />
            <span className="text-muted">Account Information</span>
            <hr className="flex-grow-1" />
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex gap-15 align-items-center">
            <hr className="flex-grow-1" />
            <span className="text-muted">Plan Details</span>
            <hr className="flex-grow-1" />
          </div>
        </div>
      </div>
      <div className="d-flex mb-4">
        <div className="flex-grow-1">
          <div
            className="d-flex flex-column gap-15 align-items-center"
            ref={formRef}
          >
            <div className="form-floating w-50">
              <input
                className="form-control"
                placeholder=""
                value={userToUpdate?.name ?? ""}
                disabled
                onChange={(e) =>
                  setUserToUpdate({ ...userToUpdate, name: e.target.value })
                }
              ></input>
              <label htmlFor="user-info-name">Name</label>
            </div>
            <div className="form-floating w-50">
              <input
                className="form-control"
                placeholder=""
                value={userToUpdate?.email ?? ""}
                disabled
                onChange={(e) =>
                  setUserToUpdate({ ...userToUpdate, email: e.target.value })
                }
              ></input>
              <label htmlFor="user-info-name">Email</label>
            </div>
            <div className="form-floating w-50">
              <input
                type="date"
                className="form-control"
                placeholder=""
                value={userToUpdate?.age ?? ""}
                disabled
                onChange={(e) =>
                  setUserToUpdate({ ...userToUpdate, age: e.target.value })
                }
              ></input>
              <label htmlFor="user-info-name">Date of Birth</label>
            </div>
            <div className="form-floating w-50">
              <select
                id="game-option-context-input"
                value={userToUpdate?.gender ?? ""}
                className="form-select"
                onChange={(e) =>
                  setUserToUpdate({
                    ...userToUpdate,
                    gender: e.target.value || null,
                  })
                }
                disabled
              >
                <option value="">Prefer not to tell</option>
                {["Male", "Female"].map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <label htmlFor="user-info-name">Gender</label>
            </div>
            <button
              type="submit"
              className={`btn btn-${saveEditButton[editButtonIndex].color}`}
              style={{ width: "fit-content" }}
              disabled={isSaving}
              onClick={() => {
                formRef.current
                  .querySelectorAll(".form-floating>input,select")
                  .forEach((input) => {
                    input.toggleAttribute("disabled");
                  });
                if (saveEditButton[editButtonIndex].name == "Save") {
                  updateUser();
                } else {
                  setEditButtonIndex(+!editButtonIndex);
                }
              }}
            >
              {isSaving ? "Saving..." : saveEditButton[editButtonIndex].name}
            </button>
            <a
              onClick={(e) => {
                showModal();
              }}
              className="clickable"
            >
              Click here if you want to change your password
            </a>
          </div>
        </div>
        <span className="vr"></span>
        <div
          className="flex-grow-1"
          style={{
            width: "min-content",
          }}
        >
          <div className="d-flex flex-column gap-15 align-items-center">
            <div
              style={{
                padding: "20px 40px",
                border: "1px solid #000",
                borderRadius: "20px",
                width: "50%",
                textAlign: "center",
                boxSizing: "content-box",
              }}
            >
              <h5>Basic</h5>
              <p className="text-start mb-2">
                Your subscription allows you to follow one class per month
              </p>
              <p className="text-start mb-2">
                <strong>Free</strong>
              </p>
              <button className="btn btn-outline-primary">Current</button>
            </div>
            <div
              style={{
                padding: "20px 40px",
                border: "1px solid #000",
                borderRadius: "20px",
                width: "50%",
                textAlign: "center",
                boxSizing: "content-box",
              }}
            >
              <h5>Premium</h5>
              <p className="text-start mb-2">
                Your subscription allows you to follow one class per month
              </p>
              <p className="text-start mb-2">
                <strong>19.90$ / month</strong>
              </p>
              <button className="btn btn-primary">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
